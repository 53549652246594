var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "review" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: (_vm.premium || !_vm.canUpdate) && !_vm.loadingPremium,
            expression: "(premium || !canUpdate) && !loadingPremium"
          }
        ]
      },
      [
        _c(
          "vs-row",
          { staticClass: "p-0 " },
          [
            _c(
              "vs-col",
              {
                staticClass: "container-element pb-0 mt-3",
                attrs: { "vs-w": "12" }
              },
              [
                _c("p", { staticClass: "label-content label" }, [
                  _vm._v(
                    "\n          Prêmio à " +
                      _vm._s(_vm.restituir ? "restituir" : "cobrar") +
                      "\n        "
                  )
                ]),
                _c("currency-input", {
                  staticClass:
                    "w-full vs-inputx vs-input--input large hasValue",
                  attrs: { value: _vm.premium, disabled: "" }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm.restituir
          ? _c(
              "vs-row",
              { staticClass: "pt-2" },
              [
                _c(
                  "vs-col",
                  {
                    staticClass: "pt-0",
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "end",
                      "vs-w": "12"
                    }
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "px-1",
                        attrs: { type: "flat" },
                        on: {
                          click: function($event) {
                            return _vm.solicitarReview()
                          }
                        }
                      },
                      [_vm._v("\n          Solicitar revisão\n        ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e(),
        _vm.IsBankInformationNeeded
          ? _c(
              "div",
              [
                _vm.restituir && (_vm.premium || !_vm.canUpdate)
                  ? [
                      _c(
                        "vs-row",
                        [
                          _c("vs-col", { attrs: { "vs-w": "12" } }, [
                            _c(
                              "h4",
                              {
                                staticClass:
                                  "vx-col w-full flex items-center font-semibold p-0"
                              },
                              [
                                _vm._v(
                                  "\n              Dados Bancários do tomador\n            "
                                )
                              ]
                            )
                          ]),
                          _vm.policyHolderBankInfo.BankAccountTypeId
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "vs-col",
                                    {
                                      staticClass: "container-element",
                                      attrs: { "vs-w": "12" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "label-content label" },
                                        [
                                          _vm._v(
                                            "\n                Banco\n              "
                                          )
                                        ]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "banco",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "Name",
                                                      size: "large",
                                                      placeholder:
                                                        "Selecione um banco",
                                                      value:
                                                        _vm.policyHolderBankInfo
                                                          .BankId,
                                                      options: _vm.bancos,
                                                      dir: _vm.$vs.rtl
                                                        ? "rtl"
                                                        : "ltr",
                                                      reduce: function(banco) {
                                                        return banco.Id
                                                      },
                                                      disabled: true
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.updateEndosso({
                                                          PolicyHolder: Object.assign(
                                                            {},
                                                            _vm.endosso
                                                              .PolicyHolder ||
                                                              {},
                                                            {
                                                              BankAccount: Object.assign(
                                                                {},
                                                                (
                                                                  _vm.endosso
                                                                    .PolicyHolder ||
                                                                  {}
                                                                ).BankAccount ||
                                                                  {},
                                                                {
                                                                  BankId: $event
                                                                }
                                                              )
                                                            }
                                                          )
                                                        })
                                                      }
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          133025441
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    {
                                      staticClass: "container-element",
                                      attrs: { "vs-w": "12" }
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "label-content label" },
                                        [
                                          _vm._v(
                                            "\n                Tipo da conta\n              "
                                          )
                                        ]
                                      ),
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "tipo da conta",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("v-select", {
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "Name",
                                                      size: "large",
                                                      placeholder:
                                                        "Selecione um tipo",
                                                      value:
                                                        _vm.policyHolderBankInfo
                                                          .BankAccountTypeId,
                                                      options:
                                                        _vm.tipoContaList,
                                                      dir: _vm.$vs.rtl
                                                        ? "rtl"
                                                        : "ltr",
                                                      reduce: function(banco) {
                                                        return banco.Id
                                                      },
                                                      disabled: true
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.updateEndosso({
                                                          PolicyHolder: Object.assign(
                                                            {},
                                                            _vm.endosso
                                                              .PolicyHolder ||
                                                              {},
                                                            {
                                                              BankAccount: Object.assign(
                                                                {},
                                                                (
                                                                  _vm.endosso
                                                                    .PolicyHolder ||
                                                                  {}
                                                                ).BankAccount ||
                                                                  {},
                                                                {
                                                                  BankAccountTypeId: $event
                                                                }
                                                              )
                                                            }
                                                          )
                                                        })
                                                      }
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          378701831
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "8", "vs-sm": "12" } },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "agência",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("vs-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: [
                                                          "###",
                                                          "####",
                                                          "#####",
                                                          "######",
                                                          "#######"
                                                        ],
                                                        expression:
                                                          "['###', '####', '#####', '######', '#######']"
                                                      }
                                                    ],
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "Agência",
                                                      size: "large",
                                                      value:
                                                        _vm.policyHolderBankInfo
                                                          .BankBranchNumber,
                                                      disabled: true
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.updateEndosso({
                                                          PolicyHolder: Object.assign(
                                                            {},
                                                            _vm.endosso
                                                              .PolicyHolder ||
                                                              {},
                                                            {
                                                              BankAccount: Object.assign(
                                                                {},
                                                                (
                                                                  _vm.endosso
                                                                    .PolicyHolder ||
                                                                  {}
                                                                ).BankAccount ||
                                                                  {},
                                                                {
                                                                  BankBranchNumber: $event
                                                                }
                                                              )
                                                            }
                                                          )
                                                        })
                                                      }
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1756528557
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["#", "##"],
                                            expression: "['#', '##']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          label: "Digito",
                                          size: "large",
                                          value:
                                            _vm.policyHolderBankInfo
                                              .BankBranchDigit,
                                          disabled: true
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.updateEndosso({
                                              PolicyHolder: Object.assign(
                                                {},
                                                _vm.endosso.PolicyHolder || {},
                                                {
                                                  BankAccount: Object.assign(
                                                    {},
                                                    (
                                                      _vm.endosso
                                                        .PolicyHolder || {}
                                                    ).BankAccount || {},
                                                    { BankBranchDigit: $event }
                                                  )
                                                }
                                              )
                                            })
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "8", "vs-sm": "12" } },
                                    [
                                      _c("ValidationProvider", {
                                        attrs: {
                                          name: "conta",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("vs-input", {
                                                    directives: [
                                                      {
                                                        name: "mask",
                                                        rawName: "v-mask",
                                                        value: [
                                                          "###",
                                                          "####",
                                                          "#####",
                                                          "######",
                                                          "#######",
                                                          "####################"
                                                        ],
                                                        expression:
                                                          "[\n                    '###',\n                    '####',\n                    '#####',\n                    '######',\n                    '#######',\n                    '####################'\n                  ]"
                                                      }
                                                    ],
                                                    staticClass: "w-full",
                                                    attrs: {
                                                      label: "Conta",
                                                      size: "large",
                                                      value:
                                                        _vm.policyHolderBankInfo
                                                          .BankAccountNumber,
                                                      disabled: true
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        _vm.updateEndosso({
                                                          PolicyHolder: Object.assign(
                                                            {},
                                                            _vm.endosso
                                                              .PolicyHolder ||
                                                              {},
                                                            {
                                                              BankAccount: Object.assign(
                                                                {},
                                                                (
                                                                  _vm.endosso
                                                                    .PolicyHolder ||
                                                                  {}
                                                                ).BankAccount ||
                                                                  {},
                                                                {
                                                                  BankAccountNumber: $event
                                                                }
                                                              )
                                                            }
                                                          )
                                                        })
                                                      }
                                                    }
                                                  }),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: errors[0],
                                                          expression:
                                                            "errors[0]"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-danger text-sm"
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                  " +
                                                          _vm._s(errors[0]) +
                                                          "\n                "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          2168452811
                                        )
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "vs-col",
                                    { attrs: { "vs-lg": "4", "vs-sm": "12" } },
                                    [
                                      _c("vs-input", {
                                        directives: [
                                          {
                                            name: "mask",
                                            rawName: "v-mask",
                                            value: ["#"],
                                            expression: "['#']"
                                          }
                                        ],
                                        staticClass: "w-full",
                                        attrs: {
                                          label: "Digito",
                                          size: "large",
                                          value:
                                            _vm.policyHolderBankInfo
                                              .BankAccountDigit,
                                          disabled: true
                                        },
                                        on: {
                                          input: function($event) {
                                            _vm.updateEndosso({
                                              PolicyHolder: Object.assign(
                                                {},
                                                _vm.endosso.PolicyHolder || {},
                                                {
                                                  BankAccount: Object.assign(
                                                    {},
                                                    (
                                                      _vm.endosso
                                                        .PolicyHolder || {}
                                                    ).BankAccount || {},
                                                    { BankAccountDigit: $event }
                                                  )
                                                }
                                              )
                                            })
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c("el-alert", {
                                staticClass: "m-5",
                                attrs: {
                                  title: "Atenção",
                                  type: "warning",
                                  description:
                                    "Necessário incluir dados bancários para esse tomador!",
                                  closable: false,
                                  "show-icon": ""
                                }
                              }),
                          _c(
                            "vs-col",
                            {
                              staticClass: "pt-0",
                              attrs: {
                                "vs-type": "flex",
                                "vs-justify": "center",
                                "vs-align": "end",
                                "vs-w": "12"
                              }
                            },
                            [
                              _c(
                                "vs-button",
                                {
                                  staticClass: "px-5",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function($event) {
                                      _vm.bankInfoModal = true
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Gerenciar dados Bancários\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          : _vm._e(),
        _c(
          "vs-popup",
          {
            staticClass: "modal-bank",
            attrs: { title: "", id: "review-popup", active: _vm.bankInfoModal },
            on: {
              "update:active": function($event) {
                _vm.bankInfoModal = $event
              }
            }
          },
          [
            _vm.bankInfoModal
              ? _c("dadosBancariosComponent", {
                  attrs: {
                    mode: "endosso",
                    personId: 0,
                    personUniqueId:
                      _vm.$store.state["apolice-module"].apolice.PolicyHolder
                        .UniqueId
                  },
                  on: { callSelectedBank: _vm.callSelectedBank }
                })
              : _vm._e()
          ],
          1
        ),
        _c(
          "vs-popup",
          {
            attrs: {
              title: "Solicitar revisão",
              id: "review-popup",
              active: _vm.reviewModal
            },
            on: {
              "update:active": function($event) {
                _vm.reviewModal = $event
              }
            }
          },
          [
            _c(
              "vs-row",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.endossoAutomatico,
                    expression: "!endossoAutomatico"
                  }
                ]
              },
              [
                _c(
                  "vs-col",
                  { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("Valor sugerido")
                    ]),
                    _c("currency-input", {
                      staticClass:
                        "w-full vs-inputx vs-input--input large hasValue",
                      attrs: { value: _vm.premio, disabled: "" }
                    })
                  ],
                  1
                ),
                _c(
                  "vs-col",
                  { attrs: { "vs-lg": "6", "vs-sm": "12" } },
                  [
                    _c("p", { staticClass: "label-content label" }, [
                      _vm._v("\n            Valor solicitado\n          ")
                    ]),
                    _c("ValidationProvider", {
                      attrs: {
                        name: "Valor solicitado",
                        rules: { required: _vm.reviewModal }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("currency-input", {
                                staticClass:
                                  "w-full vs-inputx vs-input--input large hasValue",
                                model: {
                                  value: _vm.novoPremio,
                                  callback: function($$v) {
                                    _vm.novoPremio = $$v
                                  },
                                  expression: "novoPremio"
                                }
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]"
                                    }
                                  ],
                                  staticClass: "text-danger text-sm"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(errors[0]) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "vs-col",
                  { attrs: { "vs-lg": "12", "vs-sm": "12" } },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        name: "Justificativa",
                        rules: { required: _vm.reviewModal }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function(ref) {
                            var errors = ref.errors
                            return [
                              _c("vs-input", {
                                staticClass: "w-full",
                                attrs: {
                                  label: "Justificativa",
                                  size: "large",
                                  value:
                                    _vm.endosso
                                      .JustificationInsurancePremiumReview
                                },
                                on: {
                                  input: function($event) {
                                    return _vm.updateEndosso({
                                      JustificationInsurancePremiumReview: $event
                                    })
                                  }
                                }
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: errors[0],
                                      expression: "errors[0]"
                                    }
                                  ],
                                  staticClass: "text-danger text-sm"
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(errors[0]) +
                                      "\n            "
                                  )
                                ]
                              )
                            ]
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "vs-row",
              { staticClass: "mt-5 px-4" },
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "center",
                      "vs-lg": "3",
                      "vs-sm": "12"
                    }
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        on: {
                          click: function($event) {
                            return _vm.updateReviewData()
                          }
                        }
                      },
                      [_vm._v("\n            Salvar\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }